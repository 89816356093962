import {isEmpty, formatFieldName} from 'utils/helpers';
import {MAX_PHOTO_SIZE_MB} from 'core/constants';
import {SubmissionError} from 'redux-form';
import {searchEmails} from 'features/users/actions';

const errorReducer = (arr) => {
    if (typeof arr === 'string' || !arr) return arr;
    return arr
        .filter((e) => e)
        .reduce((acc, cur) => `${acc}${acc.length > 0 ? ',' : ''} ${cur}`, '');
};

const creditCardLength = (str) => {
    const replaced = str.replace(/\s/g, '');
    return replaced.length < 14 || replaced.length > 16
        ? 'Must be between and 14-16 characters'
        : null;
};
const validCvc = (str) => (!/^[0-9|*]/.test(str) ? 'Must only include numbers' : null);
const validName = (str) =>
    !/^[a-zA-Z\s\-.']+$/.test(str) ? 'Must not include symbols' : null;
const validRoleName = (str) =>
    !/^[a-zA-Z0-9\s\-.'\s]+$/.test(str) ? 'Must not contain symbols' : null;
const lettersOnly = (str) =>
    !/^[a-zA-Z]+$/.test(str) ? 'Must only include letters' : null;
const numbersOnly = (str) => {
    return !/^[0-9]*$/gm.test(str) ? 'Must only contain numbers' : null;
};
const numbersAndPeriodsOnly = (str) => {
    return !/^[0-9.]*$/gm.test(str) ? 'Must only contain numbers and periods' : null;
};
const numbersAndStarsOnly = (str) => {
    const replaced = str.replace(/[*\s]/g, '');
    return !/^[0-9]*$/gm.test(replaced) ? 'Must only contain numbers' : null;
};
const phoneNumber = (str) => {
    if (str) {
        const numsOnly = str
            .toString()
            .split('')
            .filter((s) => /\d/.test(s))
            .join('');
        return errorReducer([
            numsOnly.length < 10 || numsOnly.length > 11
                ? 'Must be a valid U.S. phone number + area code'
                : null,
            !/^[0-9\s.()\-+]*$/gm.test(str)
                ? 'Must not contain letters or symbols'
                : null,
        ]);
    }

    return null;
};
const validUrl = (str) => {
    if (str) {
        return errorReducer([
            !/^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}(\/\S*)?$/gm.test(str)
                ? 'Must be a valid url'
                : null,
        ]);
    }

    return null;
};

const ofLength2 = (str) =>
    str && str.length < 2 ? 'Must contain at least 2 characters' : null;
const ofLength3 = (str) =>
    str && str.length < 3 ? 'Must contain at least 3 characters' : null;
const ofLength4 = (str) =>
    str && str.toString().length !== 4 ? 'Must be 4 characters' : null;
const is4DigitYear = (str) =>
    str && !/^\d{4}$/.test(str.toString()) ? 'Must be 4 digit year' : null;
export const yearGteCurrent = (str) =>
    str && new Date().getFullYear() > parseInt(str) ? 'Expiration year not valid' : null;

export const monthValid = (month, year) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    if (parseInt(year) > currentYear) {
        return null;
    } else if (parseInt(year) === currentYear && parseInt(month) - 1 < currentMonth) {
        return 'Expiration month not valid';
    }
    return null;
};

const ofLength7 = (str) => (str && str.length !== 5 ? 'Must contain 5 characters' : null);

export const ofLength10 = (str) =>
    str && str.length < 10 ? 'Must contain at least 10 characters' : null;
export const hasOneNumber = (str) =>
    !str.match(/[0-9]/) ? 'Must contain a number' : null;
export const hasLower = (str) =>
    !str.match(/[a-z]/) ? 'Must contain a lowercase letter' : null;
export const hasUpper = (str) =>
    !str.match(/[A-Z]/) ? 'Must contain an uppercase letter' : null;

const oneDecimal = (str) => {
    if (typeof str !== 'string') return;

    const splitByDecimal = str.split('.');

    if (splitByDecimal.length > 2) {
        return 'Must not contain more than one decimal';
    }

    /**
     * Allow no more than two numbers after decimal
     */
    const sliced = splitByDecimal.slice(1);
    if (sliced.find((s) => s.length > 2)) {
        return 'Must be a valid dollar amount';
    }

    return null;
};

const atLeast500 = (str) => {
    if (typeof str !== 'string') return;

    const splitByDecimal = str.split('.');

    if (str === '0.00') {
        return null;
    }

    const beforeDecimal = +splitByDecimal[0];

    if (beforeDecimal < 500) {
        return 'Must be at least $500';
    }

    return null;
};

const atLeast2000 = (str) => {
    if (typeof str !== 'string') return;

    const splitByDecimal = str.split('.');

    if (splitByDecimal.length > 2) {
        return 'Must not contain more than one decimal';
    }

    const beforeDecimal = +splitByDecimal[0];

    if (beforeDecimal < 2000) {
        return 'Must be at least $2000';
    }

    return null;
};

const lessThan5000 = (val) => {
    if (!val && typeof val !== 'number') return;

    let num = parseInt(val);

    if (isNaN(num) || isNaN(val)) {
        console.warn('Cannot convert ' + val + ' to a number');
        return 'Must specify a valid amount';
    }

    return num <= 5000 ? null : 'Must be less than or equal to $5,000';
};

const validPriceMultiplier = (number) => {
    if (number > 1 || number < 0) {
        return 'Number must be between 0 and 1';
    }
};

export const validEmail = (str) => {
    // see https://en.wikipedia.org/wiki/Email_address and https://en.wikipedia.org/wiki/Hostname for rules.
    // some rules not applied for brevity. For, example, "quoted strings" are treated as false here, but are allowed.
    const invalid = ['Email invalid'];
    if (!str) return;
    const emailParts = str.split('@');
    if (emailParts.length !== 2) {
        return invalid;
    }
    const localPart = emailParts[0];
    const domain = emailParts[1];
    if (localPart.length > 64 || domain.length > 255) {
        return invalid;
    }

    if (/".+"/.test(localPart)) {
        // don't validate quoted local parts
        return invalid;
    } else {
        if (/^\./.test(localPart) || /\.$/.test(localPart) || /\.\./.test(localPart)) {
            return invalid;
        } else if (!/^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+$/.test(localPart)) {
            return invalid;
        }
    }

    // can't end or start with '.'  can't end with '-'
    if (/^\./.test(domain) || /[.-]$/.test(domain) || /\.\./.test(domain)) {
        return invalid;
    }

    let domainParts = domain.split('.');

    // must have 1 dot
    if (domainParts.length < 2) {
        return invalid;
    }
    // don't allow brace delimited ipv4 or ipv6 domains ex: john@[11.11.11.11]. These are spammy.
    for (let i = 0; i < domainParts.length; i++) {
        if (domainParts[i].length > 63) {
            return invalid;
        }

        // ibe letter subdomains
        if (domainParts[i].length === 1) {
            if (!/^[a-zA-Z0-9]$/.test(domainParts[i])) {
                return invalid;
            }
            // skip 2+ letter subdomains
            continue;
        }

        // 2+ letter or greater
        if (!/^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/.test(domainParts[i])) {
            return invalid;
        }
    }

    // tld specfic rules
    const tld = domainParts[domainParts.length - 1];
    if (/^\d+$/.test(tld)) {
        return invalid;
    } else if (tld.length < 2) {
        return invalid;
    }

    return;
};

export const checkEmailExistsForm = (values, initialValues, getState) => {
    // check for user edit form, don't check existing value for email.
    if (initialValues?.email && values.email === initialValues.email) {
        return values;
    }

    return new Promise((resolve, reject) => {
        return searchEmails(values.email, getState)
            .then((matchedUsers) => {
                if (matchedUsers?.length > 0) {
                    return reject(
                        new SubmissionError({
                            email: `email ${values.email} already exists`,
                        }),
                    );
                } else {
                    return resolve(values);
                }
            })
            .catch((e) => {
                let message =
                    `Could not validate email ${values.email}` +
                    (': ' + e.message ? e.message : '');
                return reject(new SubmissionError({email: message}));
            });
    });
};

export const validatePhotos = (acc, photo, i) => {
    const validPhotoTypes = ['image/jpeg', 'image/png'];

    /**
     * Do not validate urls
     */
    if (!photo || !photo.file_name || typeof photo.file_name === 'string') {
        return acc;
    }

    /**
     * Check correct file extension
     */
    if (!validPhotoTypes.includes(photo.file_name.type)) {
        const fileType =
            photo.file_name.type && photo.file_name.type.split('/').slice(-1)[0];

        acc[i] = {
            file_name: `File type must be jpg, jpeg, or png ${
                fileType && '(' + fileType + ' provided)'
            }`,
        };
    }

    /**
     * Check if photo file name contains a comma
     * (AWS does not support commas)
     */
    const isValidFileName = (str) => /^[a-zA-Z0-9.\\\-\][\s()_]+$/.test(str);
    if (photo.file_name.name && !isValidFileName(photo.file_name.name)) {
        acc[i] = {file_name: 'No commas or special characters allowed'};
    }

    /**
     * Check correct file size
     */
    if (photo.file_name.size / 1000000 > MAX_PHOTO_SIZE_MB) {
        acc[i] = {
            file_name: ` (${Math.round(
                photo.file_name.size / 1000000,
            )}MB), must be less than ${MAX_PHOTO_SIZE_MB}MB`,
        };
    }

    return acc;
};

const validateUrls = (acc, link, i) => {
    if (!link || !link.url) {
        return acc;
    }
    if (link && link.url) {
        acc[i] = {url: validUrl(link.url)};
    }
    return acc;
};

const validators = {
    role_name: (str) => errorReducer([validRoleName(str), ofLength2(str)]),
    organization_name: (str) => errorReducer([ofLength2(str)]),
    first_name: (str) => errorReducer([ofLength2(str), validName(str)]),
    last_name: (str) => errorReducer([ofLength2(str), validName(str)]),
    job_title: (str) => errorReducer([ofLength2(str)]),
    email: (str) => errorReducer([validEmail(str)]),
    edited_author_email: (str) => errorReducer([validEmail(str)]),
    author_email: (str) => errorReducer([validEmail(str)]),
    phone_number: (str) => errorReducer([phoneNumber(str)]),
    phone: (str) => errorReducer([phoneNumber(str)]),
    phones: (arr) =>
        arr?.reduce((acc, phone, i) => {
            if (!phone || !phone.number) {
                return acc;
            }
            if (phone && phone.number) {
                acc[i] = {number: phoneNumber(phone.number)};
            }
            return acc;
        }, {}),
    call_tracking_number: (str) => errorReducer([phoneNumber(str)]),
    city: (str) => errorReducer([validName(str)]),
    zip_code: (str) => errorReducer([ofLength7(str), numbersOnly(str)]),
    billing_zip_code: (str) => errorReducer([ofLength7(str), numbersOnly(str)]),
    cc_number: (str) => errorReducer([numbersAndStarsOnly(str), creditCardLength(str)]),
    cc_expiration_year: (str) => errorReducer([is4DigitYear(str), yearGteCurrent(str)]),
    cc_cvc: (str) => errorReducer([validCvc(str)]),
    initial_balance: (str) => errorReducer([lessThan5000(str)]),
    auto_refill_threshold: (str) =>
        errorReducer([numbersAndPeriodsOnly(str), oneDecimal(str), atLeast500(str)]),
    amount: (str) => errorReducer([numbersAndPeriodsOnly(str), oneDecimal(str)]),
    advance_credit_limit: (str) =>
        errorReducer([numbersAndPeriodsOnly(str), oneDecimal(str)]),
    check_number: (str) => errorReducer([validRoleName(str)]),
    photos: (arr) => arr?.reduce(validatePhotos, {}),
    price_multiplier_standard: (str) => errorReducer([validPriceMultiplier(str)]),
    price_multiplier_promoted: (str) => errorReducer([validPriceMultiplier(str)]),
    virtual_tour_links: (arr) => arr?.reduce(validateUrls, {}),
    keyword_optimized_links: (arr) => arr?.reduce(validateUrls, {}),
    social_media_links: (arr) => arr?.reduce(validateUrls, {}),
};

export function validate(values) {
    if (isEmpty(values)) return {};

    const keys = Object.keys(values);

    const errors = keys.reduce((acc, key) => {
        if (validators[key]) {
            acc[key] = validators[key](values[key]);
        }

        return acc;
    }, {});

    if ('cc_expiration_year' in values && 'cc_expiration_month' in values) {
        const errorMessageMonth = monthValid(
            values['cc_expiration_month'],
            values['cc_expiration_year'],
        );
        errors['cc_expiration_month'] = errorMessageMonth ? errorMessageMonth : '';
    }

    return errors;
}

export function checkRequiredFields(values, ...fields) {
    if (typeof values !== 'object') {
        console.warn('Must pass in an object whose values to check');
    }

    const promise = new Promise((resolve, reject) => {
        const rejectedValues = fields.reduce((acc, cur) => {
            if (!cur.match(/\./)) {
                if (isEmpty(values[cur])) {
                    //acc[cur] = formatFieldName(cur) + ' is a required field'
                    acc[cur] = 'This is a required field';
                }
                return acc;
            }

            if (cur.match(/\./g)) {
                const [key1, key2] = cur.split('.');

                if (isEmpty(values[key1]) || isEmpty(values[key1][key2])) {
                    acc[key1] = {[key2]: 'Required'};
                    //acc[key1] = {[key2]: formatFieldName(key2) + ' is required'}
                }

                return acc;
            }

            return acc;
        }, {});

        if (!isEmpty(rejectedValues)) {
            return reject(new SubmissionError(rejectedValues));
        }

        return resolve(values);
    });

    return promise;
}

export function password(values) {
    if (!values || !values.password) return {};

    return {
        password: errorReducer([
            ofLength10(values.password),
            hasOneNumber(values.password),
            hasLower(values.password),
            hasUpper(values.password),
        ]),
    };
}
